import React from 'react'

import UploadFileIcon from '@mui/icons-material/UploadFile'

export const PAGES = {
  UPLOAD_CNAB: {
    id: 'upload',
    url: '/upload',
    name: 'Upload CNAB',
    icon: <UploadFileIcon />
  }
}

export const DATE_FORMAT = {
  DAILY: 'MM/dd/yyyy',
  MONTHLY: 'MM/yyyy'
}

export const CSV_ENCODING = 'ISO-8859-1'
export const CSV_DELIMITER = ';'
export const BUCKET_NAME = 'atrio-warehouse'
export const STATE_MACHINE_NAME = 'CnabValidationStarter'
export const SQS_PROVIDER_NAME = STATE_MACHINE_NAME
