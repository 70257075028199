import React from 'react'
import { Auth } from 'aws-amplify'
import { Paper, Typography, Box, Button } from '@mui/material'

import LoginIcon from '../../assets/icons/LoginIcon'

import { useHandleError } from '../../utils/hooks'

export default function Login() {
  const { handleError } = useHandleError()
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: 'secondary.main',
      height: '100vh',
      overflow: 'hidden'
    }}>
      <Paper
        sx={{
          paddingY: 2,
          paddingX: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#ffffff',
          overflowY: 'auto',
          maxHeight: '90vh',
          width: '75%',
          maxWidth: '600px'
        }}
      >
        <LoginIcon style={{ height: 200 }} />
        <Typography component='h1' variant='h5'>
          É bom ver você aqui novamente
        </Typography>
        <Typography component='h2' variant='caption'>
          Entre agora e veja todos os insights que proporcionamos para você!
        </Typography>
        <Button
          type='button'
          fullWidth
          variant='contained'
          sx={{ mt: 3, mb: 2, maxWidth: '500px' }}
          onClick={() => Auth.federatedSignIn().catch(handleError)}
        >
          Entrar
        </Button>
      </Paper>
    </Box>
  )
}
