import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'

export default function Header({ children }: { children: ReactNode }): JSX.Element {
  return (
    <Helmet>
      {children}
    </Helmet>
  )
}
