import React from 'react'
import { ErrorBoundary as ErrorBoudaryComponent } from 'react-error-boundary'

import UnexpectedErroPage from './UnexpectedErrorPage'

export default function ErrorBoundary({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <ErrorBoudaryComponent
      FallbackComponent={UnexpectedErroPage}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoudaryComponent>
  )
}
