import React from 'react'
import { CircularProgress, Box } from '@mui/material'

export default function LoadingContainer({ minHeight }: { minHeight?: number }): JSX.Element {
  return (
    <Box sx={{ display: 'flex', flex: 1, height: '100%', justifyContent: 'center', alignItems: 'center', minHeight }}>
      <CircularProgress />
    </Box>
  )
}
