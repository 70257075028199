import React, { lazy } from 'react'

export default function lazyLoad<T>(
  importFn: () => Promise<{ default: React.ComponentType<T> }>,
  errorComponent: () => React.ReactElement<T>,
  retries?: number,
  interval?: number
): React.LazyExoticComponent<React.ComponentType<T>> {
  const Component = errorComponent

  return lazy(() => retry(importFn, retries, interval).catch(err => {
    console.error(err)
    return {
      default: Component
    }
  })
  )
}

function retry<T>(
  fn: () => Promise<{ default: React.ComponentType<T> }>,
  retriesLeft = 5,
  interval = 1_000
): Promise<{ default: React.ComponentType<T> }> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
            return
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}
