import { createTheme } from '@mui/material/styles'
import { colors } from '@mui/material'
import { LIST_BACKGROUND_COLOR, LIST_COLOR, PRIMARY_COLOR, SECONDARY_COLOR } from './env'

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    secondary: {
      main: SECONDARY_COLOR
    },
    error: {
      main: colors.red.A400
    }
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: LIST_BACKGROUND_COLOR,
            color: '#fff',
            fill: '#fff',
            '&, & .MuiListItemIcon-root': {
              color: LIST_COLOR
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              '&, & .MuiListItemIcon-root': {
                color: 'rgba(0, 0, 0, 0.54)'
              }
            }
          }

        }
      }
    }
  }
})

export default theme
